<template>
  <b-row v-if="item">
   
    <b-col cols="12">
      <center>
      <video :src="item.basis" controls></video>
      </center>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BBadge, BCardText } from "bootstrap-vue";

export default {
  name: "TrainingCourseView",
  props: {
    item: {
      type: Object,
      default: null,
    },
  },

  components: {
    BRow,
    BCol,
    BBadge,
    BCardText,
  },

  data() {
    return {
      //
    };
  },
};
</script>
